<template>
    <el-row :gutter="15">
      <el-col :xs="24">
        <!-- <div class="back">
          <el-button type="info" class="mb-3" @click="$router.go(-1)"><i class="el-icon-back mr-2" />Back</el-button>
        </div> -->
        <hr>
        <div class="jobview freelancer">
          <el-card>
            <div class="header">
              <center>
                <h4 class="orange-text">{{ show.title }}</h4>
                <div class="overview">
                    <h5><i class="el-icon-timer" /> {{ show.job_type }}</h5>
                  <!-- <h5><i class="el-icon-money"></i> $1000-$2000 | <i class="el-icon-timer"></i> {{ show.job_type }}Full-time</h5> -->
                  <!-- <h5><i class="el-icon-timer" /> {{ show.job_type }}</h5> -->
                </div>
                <p class="small mt-3 mb-0 gray-text">Date posted: {{ show.created_at }}</p>
                <!-- <p class="small mt-3 mb-0 gray-text">Date posted: {{ show.created_at }}23rd September 2020</p> -->
              </center>
            </div>
          </el-card>
          <el-card class="mt-3">
            <div class="body pt-0">
              <div class="details">
                <div class="intro screening-answer mt-3">
                  <center>
                    <h5 class="orange-text" style="font-weight: 600;">Sorry, you did not meet the requirement for this job, <br class="hidden-xs"> please click here to go back to the job search.</h5>
                  </center>
                </div>
                <center>
                  <router-link tag="button" class="el-button el-button--success mt-2" to="/freelancer/jobs">Go Back</router-link>
                </center>
                
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
</template>

<script>
import store from '../../vuex/store'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { JOBS, LOCAL_BASE_URL } from '../../vuex/utils/constant'
import getUser from '~/helpers/getUser'
import Model from '~/models/Model'
const USER = getUser()

export default {
  store,
  data () {
    return {
      answers: [{
        value: 'Video',
        label: 'Video'
      }, {
        value: 'Photo',
        label: 'Photo'
      }, {
        value: 'Photo & video',
        label: 'Photo & video'
      }],
      value: '',
      file: {
        limit: 1,
        action: 'https://jsonplaceholder.typicode.com/posts/',
        multiple: false,
        showFileList: true,
        accept: '.jpg, .png, .pdf',
        withcredentials: false,
        fileList: [],
        validation: {
          limit: '',
          size: '',
        },
      },
      upload: LOCAL_BASE_URL + JOBS + `/apply/${this.$route.params.job_id}/upload`,
      labelPosition: 'top',
      jobApplication: {
        subject: '',
        message: '',
        upload: '',
      },
      click: false,
      job_id: this.$route.params.job_id,
      f_id: USER.freelancer_id,
      uploadHeaders: Model.headers(),
      rules: {
        // subject: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (!value) {
        //         return callback(new Error('The subject field is required'))
        //       }
        //       if (value.length >= 255) {
        //         callback(new Error('The subject field must have a maximum 255 characters'))
        //       }
        //     }, trigger: ['blur']
        //   }
        // ],
        // message: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (!value) {
        //         return callback(new Error('The message field is required'))
        //       }
        //       if (value.length >= 500) {
        //         callback(new Error('The message field must have a maximum 500 characters'))
        //       }
        //     }, trigger: ['blur']
        //   }
        // ],
      },
    }
  },
  computed: {
    ...mapGetters({
      applicationErr: 'jobApplicationError',
      applicationSuccess: 'jobApplicationSuccess',
      show: 'getJobsDetails',
    }),
  },
  mounted () {
    this.$store.dispatch('jobView', { job_id: this.job_id, f_id: this.f_id })
  },
  methods: {
    ...mapActions({
      apply: 'jobApply',
    }),
    ...mapMutations(['clearApiError']),
    handleExceed () {
      this.file.validation.limit = 'Upload exceed limit must not more than one.'
    },
    handleBeforeUpload (file) {
      this.jobApplication.upload = file.name
      // File size limitation
      console.log(file.size / 1024 / 1024)
      const isLt5M = file.size / 1024 / 1024 < 12
      if (!isLt5M) {
        this.file.validation.size = 'Upload size must not more than 12 MB.'
      } else {
        this.file.validation.size = ''
      }

      // File type restriction
      // const name = file.name ? file.name : '';
      // const ext = name
      //         ? name.substr(name.lastIndexOf('.') + 1, name.length)
      //         : true;
      // const isExt = this.accept.indexOf(ext) < 0;
      // if (isExt) {
      //   this.$message.error('Please upload the correct format type');
      //   return !isExt;
      // }
    },
    handleSuccess () {

    },
    handleError () {
      this.$message.error('Please upload a JPG, PNG, or PDF file that is less than 12MB')
    },
    handleProgress () {

    },
    handleRemove () {
      this.jobApplication.upload = ''
    },

  },
}

</script>

<style lang="scss">
  .jobfeed {
    .search {
      padding: 30px;
    }
    .el-input-group__append button.el-button, .el-input-group__append, .el-input-group__prepend {
      color: #FFF;
      background-color: #E87722;
      border-color: #E87722;
    }
    .el-pagination {
      text-align: center;
    }
  }
  .jobview {
    .el-form-item {
      margin-bottom: 14px;
    }
    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .application-alert {
      max-width: 400px;
      width: 100%;
      margin: 0 auto 25px;
      /*margin: 3% 3% 1% 25px;*/
      font-weight: bold;
      ul li {
        list-style: none;
      }
    }
    .application-alert ul{
      margin-bottom: 5px;
    }

  }
  .upload-demo {
      width: 100%;
    .el-upload, .el-upload-dragger{
      width: 100%;
    }
  }

</style>
<style scoped>
  .screening-answer h5 {
    line-height: 1.6;
  }
</style>
